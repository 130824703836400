var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("maca-titulo-pagina", {
        attrs: { icono: "icon-user", nombrePagina: "Beneficiarios" }
      }),
      _c(
        "el-card",
        {
          staticStyle: { margin: "2%" },
          attrs: { "body-style": { padding: "1.5%" } }
        },
        [
          _c(
            "div",
            { attrs: { slot: "header" }, slot: "header" },
            [
              _c(
                "div",
                { staticStyle: { "margin-left": "0px" } },
                [
                  _c("el-input", {
                    staticClass: "buscador tamañoInputBucar",
                    attrs: {
                      "prefix-icon": "el-icon-search",
                      placeholder: "Presione ENTER para buscar"
                    },
                    on: {
                      change: function($event) {
                        return _vm.inputBuscar($event)
                      }
                    },
                    model: {
                      value: _vm.filtroNombre,
                      callback: function($$v) {
                        _vm.filtroNombre = $$v
                      },
                      expression: "filtroNombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "botonmasIcono mostrarOcultarItem",
                  staticStyle: { "margin-right": "0px" },
                  attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/beneficiario/nuevoBeneficiario")
                    }
                  }
                },
                [_vm._v("Nuevo Beneficiario")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "maca-datatable",
                {
                  attrs: {
                    url: _vm.urlTabla,
                    params: _vm.paramsTabla,
                    actualizar: _vm.actualizarTabla,
                    bloquear: _vm.bloquearTabla
                  },
                  on: {
                    "update:actualizar": function($event) {
                      _vm.actualizarTabla = $event
                    },
                    "update:bloquear": function($event) {
                      _vm.bloquearTabla = $event
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "detallePersona.dni",
                      label: "DNI",
                      "min-width": 10
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Apellido y Nombre", "min-width": 17 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  props.row.detallePersona.apellido +
                                    ", " +
                                    props.row.detallePersona.nombre
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Direccion", "min-width": 20 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detallePersona.detalleParaje != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detallePersona.detalleParaje
                                        .nombre + ", "
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detallePersona.detalleLocalidad != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detallePersona.detalleLocalidad
                                        .nombre + ", "
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detallePersona.detalleDepartamento != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      props.row.detallePersona
                                        .detalleDepartamento.nombre
                                    )
                                  }
                                })
                              : _vm._e(),
                            props.row.detallePersona.detalleProvincia != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      +" - " +
                                        props.row.detallePersona
                                          .detalleProvincia.nombre
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Edad", "min-width": 10 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.detallePersona.fechaNac != null
                              ? _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.calcularEdad(
                                        props.row.detallePersona.fechaNac
                                      )
                                    )
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "detallePersona.telefono",
                      label: "Telefono",
                      "min-width": 10
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop:
                        "detalleTipoBeneficiario[0].detalleTipoBeneficiario.nombre",
                      label: "Tipo Beneficio",
                      "min-width": 10
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "detallePersona.fallecido",
                      label: "Fallecido",
                      "min-width": 10
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.fallecido == 1
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("SI ")]
                                  )
                                ])
                              : _c("div", [_c("span", [_vm._v("NO ")])])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "tieneAcompaniante",
                      label: "Acompañante",
                      "min-width": 10
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.tieneAcompaniante == 1
                              ? _c("div", [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "orange" } },
                                    [_vm._v("SI ")]
                                  )
                                ])
                              : _c("div", [_c("span", [_vm._v("NO ")])])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "warning",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "M_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/beneficiario/modificarBeneficiario/" +
                                        props.row.id
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-edit" })]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "70" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "danger",
                                  circle: "",
                                  disabled: !_vm.$store.getters.tienePermiso(
                                    "B_USE"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.eliminar(props.row.id)
                                  }
                                }
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }