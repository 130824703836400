<template>
  <div>
    <maca-modal
      titulo="Nuevo Beneficiario"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="DNI" prop="dni">
                <el-input v-model="form.dni"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Fecha de Nacimiento" prop="fechaN">
                <el-popover
                  placement="right"
                  title=""
                  visible-arrow="true"
                  v-model="visible"
                  trigger="hover"
                >
                  <span v-if="this.yaEligioFecha == true">
                    {{ this.edad }} años</span
                  >
                  <span v-else>No seleccionó fecha</span>

                  <el-date-picker
                    slot="reference"
                    v-model="form.fechaN"
                    type="date"
                    :picker-options="pickerOptions"
                    placeholder="Seleccionar fecha"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    @change="calcularEdad()"
                  >
                  </el-date-picker>
                </el-popover>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Razon Social" prop="razonSocial">
                <el-input maxlength="20" v-model="form.razonSocial"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="CUIL/CUIT" prop="CUIT">
                <el-input v-model="form.cuit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Email" prop="email">
            <el-input
              class="inputEmail"
              maxlength="300"
              v-model="form.email"
            ></el-input>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Nombre" prop="nombre">
                <el-input v-model="form.nombre"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Apellido" prop="apellido">
                <el-input v-model="form.apellido"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Domicilio" prop="domicilio">
            <el-input maxlength="30" v-model="form.domicilio"></el-input>
          </el-form-item>

          <div class="contenedor">
            <el-form-item label="Departamento" prop="departamento">
              <el-select
                v-model="form.departamentoID"
                class="m-2"
                placeholder="Seleccione un departamento de la lista"
                @change="cambioDepartamento"
              >
                <el-option
                  v-for="item in departamentosSelect"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              v-loading="cargandoLocalidades"
              label="Localidad"
              prop="localidad"
            >
              <el-select
                :disabled="!form.departamentoID"
                v-model="form.localidadID"
                class="m-2"
                placeholder="Seleccione una localidad de la lista"
                @change="cambioLocalidad"
              >
                <el-option
                  v-for="item in localidadesSelect"
                  :key="item.id"
                  :label="item.nombre"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="contenedor">
            <el-form-item label="Formato de coordenadas" prop="latLongFloat">
              <el-switch
                style="display: block; margin-top: 7px"
                v-model="valueFormatoDecimales"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-text="Decimales"
                inactive-text="Grados, minutos y segundos"
              >
              </el-switch>

              <label style="color: gray; font-style: italyc"
                >Se almacenará el formato seleccionado</label
              >
            </el-form-item>

            <div v-if="valueFormatoDecimales">
              <el-form-item label="Latitud" prop="latitud">
                <el-input v-model="form.latitud"></el-input>
              </el-form-item>

              <el-form-item label="Longitud" prop="longitud">
                <el-input v-model="form.longitud"></el-input>
              </el-form-item>
            </div>

            <div v-else>
              <el-row :gutter="20">
                <el-col :span="3">
                  <el-form-item label=".">
                    <label>Latitud</label>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Grados" prop="gradosLat">
                    <el-input v-model="form.gradosLat">
                      <template slot="append">°</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Minutos" prop="minutosLat">
                    <el-input v-model="form.minutosLat">
                      <template slot="append">'</template></el-input
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Segundos" prop="segundosLat">
                    <el-input v-model="form.segundosLat">
                      <template slot="append">''</template></el-input
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item label="Direccion" prop="valueCardinalLat">
                    <el-select
                      v-model="form.valueCardinalLat"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="item in puntosCardinales"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="3">
                  <el-form-item label=".">
                    <label>Longitud</label>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Grados" prop="gradosLon">
                    <el-input v-model="form.gradosLon">
                      <template slot="append">°</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Minutos" prop="minutosLon">
                    <el-input v-model="form.minutosLon">
                      <template slot="append">'</template></el-input
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item label="Segundos" prop="segundosLon">
                    <el-input v-model="form.segundosLon">
                      <template slot="append">''</template></el-input
                    >
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item label="Direccion" prop="valueCardinalLon">
                    <el-select
                      v-model="form.valueCardinalLon"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="item in puntosCardinales"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Teléfono" prop="telefono">
                <el-input maxlength="15" v-model="form.telefono"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="Tipo de Beneficiario" prop="nombre">
            <maca-select-box
              v-model="form.tipo"
              :url="urlTiposBeneficiarios"
            ></maca-select-box>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Vive" prop="vive">
                <el-switch
                  style="display: block; margin-top: 7px"
                  v-model="valueVive"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="SI"
                  inactive-text="NO"
                >
                </el-switch>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Acompañante" prop="acompanante">
                <el-switch
                  style="display: block; margin-top: 7px"
                  v-model="valueAcompanante"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="SI"
                  inactive-text="NO"
                >
                </el-switch>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item
            v-if="!valueVive"
            label="Fecha de Fallecimiento"
            prop="fechaM"
          >
            <el-col v-if="!valueVive" :span="12">
              <el-date-picker
                v-model="form.fechaM"
                type="date"
                :picker-options="pickerOptions"
                placeholder="Seleccionar fecha"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Razon Social" prop="razonSocial">
            <el-input maxlength="20" v-model="form.razonSocial"></el-input>
          </el-form-item>
          <el-form-item label="CUIL/CUIT" prop="CUIT">
            <el-input v-model="form.cuit"></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email">
            <el-input
              class="inputEmail"
              maxlength="300"
              v-model="form.email"
            ></el-input>
          </el-form-item>

          <el-form-item label="Domicilio" prop="domicilio">
            <el-input maxlength="30" v-model="form.domicilio"></el-input>
          </el-form-item>

          <el-form-item label="Ubicación">
            <el-cascader
              :props="props"
              :show-all-levels="false"
              placeholder="Seleccionar ubicación"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <span v-if="ubicacionIncompleta" style="color: red"
            >Debe seleccionar, al menos, hasta la localidad. De lo contrario, no
            se cargará</span
          >

          <el-form-item label="Latitud" prop="latitud">
            <el-input v-model="form.latitud"></el-input>
          </el-form-item>

          <el-form-item label="Longitud" prop="longitud">
            <el-input v-model="form.longitud"></el-input>
          </el-form-item>

          <el-form-item label="Teléfono" prop="telefono">
            <el-input maxlength="15" v-model="form.telefono"></el-input>
          </el-form-item>

          <el-form-item label="Email" prop="email">
            <el-input class="inputEmail" v-model="form.email"></el-input>
          </el-form-item>

          <el-form-item label="Tipo de Beneficiario" prop="nombre">
            <maca-select-box
              v-model="form.tipo"
              :url="urlTiposBeneficiarios"
            ></maca-select-box>
          </el-form-item>

          <el-form-item label="Vive" prop="vive">
            <el-switch
              style="display: block; margin-top: 7px"
              v-model="valueVive"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="SI"
              inactive-text="NO"
            >
            </el-switch>
          </el-form-item>
          <el-form-item
            v-if="!valueVive"
            label="Fecha de Fallecimiento"
            prop="fechaM"
          >
            <el-col v-if="!valueVive" :span="12">
              <el-date-picker
                v-model="form.fechaM"
                type="date"
                :picker-options="pickerOptions"
                placeholder="Seleccionar fecha"
                format="dd-MM-yyyy"
                value-format="dd/MM/yyyy"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
.contenedor {
  background-color: #f5f7fa;
  padding: 20px;
  border-radius: 10px;
}
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    let _self = this;
    return {
      form: {
        nombre: null,
        apellido: null,
        razonSocial: null,
        cuit: null,
        domicilio: null,
        pais: null,
        provincia: null,
        departamentoID: null,
        departamento: null,
        localidadID: null,
        localidad: null,
        paraje: null,
        latitud: null,
        longitud: null,
        gradosLat: null,
        minutosLat: null,
        segundosLat: null,
        valueCardinalLat: null,
        gradosLon: null,
        minutosLon: null,
        segundosLon: null,
        valueCardinalLon: null,

        tipo: null,
        telefono: null,
        email: null,
        traslados: null,
      },
      impedirClose: false,
      persona: null,

      urlTiposBeneficiarios: "/tipoBeneficiario/obtenerTodosSelect",

      valueFormatoDecimales: true,
      puntosCardinales: [
        {
          value: "N",
          label: "N",
        },
        {
          value: "S",
          label: "S",
        },
        {
          value: "W",
          label: "W",
        },
        {
          value: "E",
          label: "E",
        },
      ],

      formRules: {
        departamento: [
          {
            required: true,
            message: "Por favor seleccione un departamento.",
            trigger: "change",
          },
        ],
        localidad: [
          {
            required: true,
            message: "Por favor seleccione una localidad.",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        gradosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca grado.",
            trigger: "change",
          },
        ],

        minutosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],
        valueCardinalLat: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca la cordialidad.",
            trigger: "change",
          },
        ],
        gradosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca grados.",
            trigger: "change",
          },
        ],
        minutosLon: [
          {
            required: !this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca minutos.",
            trigger: "change",
          },
        ],
        segundosLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca segundos.",
            trigger: "change",
          },
        ],

        valueCardinalLon: [
          {
            required: this.valueFormatoDecimales ? true : false,
            message: "Por favor introduzca la cordialidad.",
            trigger: "change",
          },
        ],

        cuit: [
          {
            required: true,
            message: "Por favor introduzca el CUIT/CUIL.",
            trigger: "change",
          },
        ],
        dni: [
          {
            required: true,
            message: "Por favor introduzca el DNI.",
            trigger: "change",
          },
        ],

        domicilio: [
          {
            required: true,
            message: "Por favor introduzca el domicilio.",
            trigger: "change",
          },
        ],
        apellido: [
          {
            required: true,
            message: "Por favor introduzca el apellido.",
            trigger: "change",
          },
        ],
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        pais: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        provincia: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
        latitud: [
          {
            required: true,
            message: "Por favor introduzca la latitud.",
            trigger: "change",
          },
        ],
        longitud: [
          {
            required: true,
            message: "Por favor introduzca la longitud.",
            trigger: "change",
          },
        ],
        telefono: [
          {
            required: true,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        fechaM: [
          {
            required: !this.valueVive,
            message: "Por favor introduzca el teléfono.",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Por favor introduzca un email correcto",
            trigger: ["blur", "change"],
          },
        ],
        tipo: [
          {
            required: true,
            message: "Por favor introduzca el tipo.",
            trigger: "change",
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value1: true,
      value2: true,
      valueVive: true,
      valueAcompanante: false,
      visible: false,
      edad: "",
      yaEligioFecha: false,
      eligioPais: false,

      urlPais: "/pais/obtenerTodosSelect",
      urlProvincia: "/provincia/obtenerTodosSelect",
      urlDepartamento: "/departamento/obtenerTodosSelect",
      urlLocalidad: "/localidad/obtenerTodosSelect",

      getParamsProvincia: this.getParamsSelectProvincia,
      getParamsDepartamento: this.getParamsSelectDepartamento,
      getParamsLocalidad: this.getParamsSelectLocalidad,

      ubicacionElegida: "",
      localidadID: null,
      departamentoID: null,
      provinciaID: null,
      paisID: null,
      parajeID: null,
      ubicacionActual: true,
      ubicacionIncompleta: false,

      departamentosSelect: [],
      localidadesSelect: [],
      cargandoLocalidades: false,
    };
  },
  methods: {
    cambioDepartamento() {
      console.log(this.form.departamentoID);
      this.getLocalidades();
    },
    cambioLocalidad() {
      console.log(this.form.localidadID);
    },
    async getDepartamentos() {
      console.log("pasa");
      let params = {
        provinciaID: 1,
        sinPaginar: 1,
      };
      await this.$maca.api.get(this.urlDepartamento, params).then((res) => {
        console.log(res);
        this.departamentosSelect = res.datos;
      });
    },
    async getLocalidades() {
      this.cargandoLocalidades = true;
      console.log(this.form.departamentoID);
      let params = {
        departamentoID: this.form.departamentoID,
        sinPaginar: 1,
      };
      await this.$maca.api
        .get("/localidad/obtenerTodos", params)
        .then((res) => {
          this.localidadesSelect = res.datos;
          console.log(this.localidadesSelect);
        });
      this.cargandoLocalidades = false;
    },
    handleChange(node) {
      if (node.length < 4) {
        this.ubicacionIncompleta = true;
        this.ubicacionActual = true;
      } else {
        for (let i = 1; i <= node.length; i++) {
          let indice = i - 1;
          if (i == 1) {
            this.paisID = node[indice];
          } else if (i == 2) {
            this.provinciaID = node[indice];
          } else if (i == 3) {
            this.departamentoID = node[indice];
          } else if (i == 4) {
            this.localidadID = node[indice];
          } else if (i == 5) {
            this.parajeID = node[indice];
          }
        }

        this.ubicacionIncompleta = false;
        this.ubicacionActual = false;
      }
    },

    getPaises() {
      let respuestaApi = this.$maca.api.get("/pais/obtenerTodos", null);
      return respuestaApi;
    },
    getParamsSelectProvincia() {
      let params = "";
      if (this.form.pais != null) {
        params = { paisID: this.form.pais.id };
      }
      return params;
    },
    getParamsSelectDepartamento() {
      let params = "";
      if (this.form.provincia != null) {
        params = { provinciaID: this.form.provincia.id };
      }
      return params;
    },
    getParamsSelectLocalidad() {
      let params = "";
      if (this.form.departamento != null) {
        params = { departamentoID: this.form.departamento.id };
      }
      return params;
    },
    getParamsSelectParaje() {
      let params = "";
      if (this.form.localidad != null) {
        params = { localidadID: this.form.localidad.id };
      }
      return params;
    },
    resetearValor(valor) {
      this.mostrarMensaje = true;
      if (valor == 1) {
        this.$refs.refProvincia.recargar();
        this.$refs.refProvinciaMobile.recargar();
        this.form.provincia = null;
        this.form.departamento = null;
        this.form.localidad = null;
        this.form.paraje = null;
        this.eligioPais = true;
      } else if (valor == 2) {
        this.$refs.refDepartamento.recargar();
        this.$refs.refDepartamentoMobile.recargar();
        this.form.departamento = null;
        this.form.localidad = null;
        this.form.paraje = null;
      } else if (valor == 3) {
        this.$refs.refLocalidad.recargar();
        this.$refs.refLocalidadMobile.recargar();
        this.form.localidad = null;
        this.form.paraje = null;
      } else if (valor == 4) {
        this.$refs.refParaje.recargar();
        this.$refs.refParajeMobile.recargar();
        this.form.paraje = null;
      }
    },
    calcularEdad() {
      let fecha1 = new Date(this.form.fechaN.toString());
      let fecha2 = new Date();
      var edad = fecha2.getFullYear() - fecha1.getFullYear();
      var m = fecha2.getMonth() - fecha1.getMonth();

      if (m < 0 || (m === 0 && fecha2.getDate() < fecha1.getDate())) {
        edad--;
        if (fecha2.getFullYear() == fecha1.getFullYear()) {
          edad++;
        }
      }
      this.edad = edad;
      this.yaEligioFecha = true;
    },
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
      this.form.nombre = null;
      this.form.apellido = null;
      this.form.razonSocial = null;
      this.form.cuit = null;
      this.form.domicilio = null;
      this.form.pais = null;
      this.form.provincia = null;
      this.form.departamento = null;
      this.form.localidad = null;
      this.form.paraje = null;
      this.form.latitud = null;
      this.form.longitud = null;
      this.form.tipo = null;
      this.form.telefono = null;
      this.form.email = null;
      this.form.traslados = null;
      this.getDepartamentos();
    },
    cerrar() {
      this.edad = "";
      this.yaEligioFecha = false;
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      let detalleTipoBeneficiario = [
        { id: 0, tipoBeneficiarioID: this.form.tipo.id, accion: "A" },
      ];

      let latitudDMS;
      let longitudDMS;
      if (!this.valueFormatoDecimales) {
        this.form.latitud = null;
        this.form.longitud = null;
        latitudDMS = {
          grados: this.form.gradosLat,
          minutos: this.form.minutosLat,
          segundos: this.form.segundosLat,
          direccion: this.form.valueCardinalLat,
        };

        longitudDMS = {
          grados: this.form.gradosLon,
          minutos: this.form.minutosLon,
          segundos: this.form.segundosLon,
          direccion: this.form.valueCardinalLon,
        };
      }

      // Hacer Post
      let params = {
        razonSocial:
          this.form.razonSocial != null ? this.form.razonSocial : "null",
        cuitCuil: this.form.cuitCuil != null ? this.form.cuitCuil : "null",
        nombre: this.form.nombre,
        apellido: this.form.apellido,
        domicilio: this.form.domicilio,
        email: this.form.email,
        dni: this.form.dni,
        fechaNac: this.form.fechaN,
        fallecidoFecha: this.form.fechaM,
        fallecido: this.form.fechaM != null ? 1 : 0,
        localidadID: this.localidadID,
        parajeID: this.localidadID,
        latitud: this.form.latitud != null ? parseFloat(this.form.latitud) : "",
        longitud:
          this.form.longitud != null ? parseFloat(this.form.longitud) : "",
        latitudDMS: latitudDMS != null ? JSON.stringify(latitudDMS) : "",
        longitudDMS: longitudDMS != null ? JSON.stringify(longitudDMS) : "",

        telefono: this.form.telefono,
        tieneAcompaniante: this.valueAcompanante,
        detalleTipoBeneficiario: JSON.stringify(detalleTipoBeneficiario),
      };
      console.log(params);
      let respuestaApi = await this.$maca.api.post(
        "/beneficiario/crear",
        params
      );

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Beneficiario creado con éxito!",
          type: "success",
        });

        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>
