var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: {
            titulo: "Nuevo Beneficiario",
            impedirClose: _vm.impedirClose
          }
        },
        [
          _c(
            "el-card",
            {
              staticClass: "mostrarOcultarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "top",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "DNI", prop: "dni" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.dni,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "dni", $$v)
                                  },
                                  expression: "form.dni"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Fecha de Nacimiento",
                                prop: "fechaN"
                              }
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    title: "",
                                    "visible-arrow": "true",
                                    trigger: "hover"
                                  },
                                  model: {
                                    value: _vm.visible,
                                    callback: function($$v) {
                                      _vm.visible = $$v
                                    },
                                    expression: "visible"
                                  }
                                },
                                [
                                  this.yaEligioFecha == true
                                    ? _c("span", [
                                        _vm._v(
                                          " " + _vm._s(this.edad) + " años"
                                        )
                                      ])
                                    : _c("span", [
                                        _vm._v("No seleccionó fecha")
                                      ]),
                                  _c("el-date-picker", {
                                    attrs: {
                                      slot: "reference",
                                      type: "date",
                                      "picker-options": _vm.pickerOptions,
                                      placeholder: "Seleccionar fecha",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.calcularEdad()
                                      }
                                    },
                                    slot: "reference",
                                    model: {
                                      value: _vm.form.fechaN,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "fechaN", $$v)
                                      },
                                      expression: "form.fechaN"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Razon Social",
                                prop: "razonSocial"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "20" },
                                model: {
                                  value: _vm.form.razonSocial,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "razonSocial", $$v)
                                  },
                                  expression: "form.razonSocial"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "CUIL/CUIT", prop: "CUIT" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.cuit,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cuit", $$v)
                                  },
                                  expression: "form.cuit"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        staticClass: "inputEmail",
                        attrs: { maxlength: "300" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nombre", prop: "nombre" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "nombre", $$v)
                                  },
                                  expression: "form.nombre"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Apellido", prop: "apellido" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.form.apellido,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "apellido", $$v)
                                  },
                                  expression: "form.apellido"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Domicilio", prop: "domicilio" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.form.domicilio,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "domicilio", $$v)
                          },
                          expression: "form.domicilio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "contenedor" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Departamento", prop: "departamento" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "m-2",
                              attrs: {
                                placeholder:
                                  "Seleccione un departamento de la lista"
                              },
                              on: { change: _vm.cambioDepartamento },
                              model: {
                                value: _vm.form.departamentoID,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "departamentoID", $$v)
                                },
                                expression: "form.departamentoID"
                              }
                            },
                            _vm._l(_vm.departamentosSelect, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.nombre, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.cargandoLocalidades,
                              expression: "cargandoLocalidades"
                            }
                          ],
                          attrs: { label: "Localidad", prop: "localidad" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "m-2",
                              attrs: {
                                disabled: !_vm.form.departamentoID,
                                placeholder:
                                  "Seleccione una localidad de la lista"
                              },
                              on: { change: _vm.cambioLocalidad },
                              model: {
                                value: _vm.form.localidadID,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "localidadID", $$v)
                                },
                                expression: "form.localidadID"
                              }
                            },
                            _vm._l(_vm.localidadesSelect, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.nombre, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "contenedor" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Formato de coordenadas",
                            prop: "latLongFloat"
                          }
                        },
                        [
                          _c("el-switch", {
                            staticStyle: {
                              display: "block",
                              "margin-top": "7px"
                            },
                            attrs: {
                              "active-color": "#13ce66",
                              "inactive-color": "#ff4949",
                              "active-text": "Decimales",
                              "inactive-text": "Grados, minutos y segundos"
                            },
                            model: {
                              value: _vm.valueFormatoDecimales,
                              callback: function($$v) {
                                _vm.valueFormatoDecimales = $$v
                              },
                              expression: "valueFormatoDecimales"
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: {
                                color: "gray",
                                "font-style": "italyc"
                              }
                            },
                            [_vm._v("Se almacenará el formato seleccionado")]
                          )
                        ],
                        1
                      ),
                      _vm.valueFormatoDecimales
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Latitud", prop: "latitud" }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.latitud,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "latitud", $$v)
                                      },
                                      expression: "form.latitud"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "Longitud", prop: "longitud" }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.form.longitud,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "longitud", $$v)
                                      },
                                      expression: "form.longitud"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "." } },
                                        [_c("label", [_vm._v("Latitud")])]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Grados",
                                            prop: "gradosLat"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.gradosLat,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "gradosLat",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.gradosLat"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("°")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Minutos",
                                            prop: "minutosLat"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.minutosLat,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "minutosLat",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.minutosLat"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("'")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Segundos",
                                            prop: "segundosLat"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.segundosLat,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "segundosLat",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.segundosLat"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("''")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Direccion",
                                            prop: "valueCardinalLat"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "Select" },
                                              model: {
                                                value:
                                                  _vm.form.valueCardinalLat,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "valueCardinalLat",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.valueCardinalLat"
                                              }
                                            },
                                            _vm._l(
                                              _vm.puntosCardinales,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "." } },
                                        [_c("label", [_vm._v("Longitud")])]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Grados",
                                            prop: "gradosLon"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.gradosLon,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "gradosLon",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.gradosLon"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("°")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Minutos",
                                            prop: "minutosLon"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.minutosLon,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "minutosLon",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.minutosLon"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("'")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 4 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Segundos",
                                            prop: "segundosLon"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value: _vm.form.segundosLon,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "segundosLon",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.segundosLon"
                                              }
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "append" },
                                                [_vm._v("''")]
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "Direccion",
                                            prop: "valueCardinalLon"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "Select" },
                                              model: {
                                                value:
                                                  _vm.form.valueCardinalLon,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "valueCardinalLon",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.valueCardinalLon"
                                              }
                                            },
                                            _vm._l(
                                              _vm.puntosCardinales,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Teléfono", prop: "telefono" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "15" },
                                model: {
                                  value: _vm.form.telefono,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "telefono", $$v)
                                  },
                                  expression: "form.telefono"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Tipo de Beneficiario", prop: "nombre" }
                    },
                    [
                      _c("maca-select-box", {
                        attrs: { url: _vm.urlTiposBeneficiarios },
                        model: {
                          value: _vm.form.tipo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tipo", $$v)
                          },
                          expression: "form.tipo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Vive", prop: "vive" } },
                            [
                              _c("el-switch", {
                                staticStyle: {
                                  display: "block",
                                  "margin-top": "7px"
                                },
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-text": "SI",
                                  "inactive-text": "NO"
                                },
                                model: {
                                  value: _vm.valueVive,
                                  callback: function($$v) {
                                    _vm.valueVive = $$v
                                  },
                                  expression: "valueVive"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Acompañante",
                                prop: "acompanante"
                              }
                            },
                            [
                              _c("el-switch", {
                                staticStyle: {
                                  display: "block",
                                  "margin-top": "7px"
                                },
                                attrs: {
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-text": "SI",
                                  "inactive-text": "NO"
                                },
                                model: {
                                  value: _vm.valueAcompanante,
                                  callback: function($$v) {
                                    _vm.valueAcompanante = $$v
                                  },
                                  expression: "valueAcompanante"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.valueVive
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Fecha de Fallecimiento",
                            prop: "fechaM"
                          }
                        },
                        [
                          !_vm.valueVive
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      "picker-options": _vm.pickerOptions,
                                      placeholder: "Seleccionar fecha",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.form.fechaM,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "fechaM", $$v)
                                      },
                                      expression: "form.fechaM"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "ocultarMostrarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "left",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Razon Social", prop: "razonSocial" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20" },
                        model: {
                          value: _vm.form.razonSocial,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "razonSocial", $$v)
                          },
                          expression: "form.razonSocial"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "CUIL/CUIT", prop: "CUIT" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.cuit,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cuit", $$v)
                          },
                          expression: "form.cuit"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        staticClass: "inputEmail",
                        attrs: { maxlength: "300" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Domicilio", prop: "domicilio" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.form.domicilio,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "domicilio", $$v)
                          },
                          expression: "form.domicilio"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Ubicación" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.props,
                          "show-all-levels": false,
                          placeholder: "Seleccionar ubicación"
                        },
                        on: { change: _vm.handleChange }
                      })
                    ],
                    1
                  ),
                  _vm.ubicacionIncompleta
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          "Debe seleccionar, al menos, hasta la localidad. De lo contrario, no se cargará"
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Latitud", prop: "latitud" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.latitud,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "latitud", $$v)
                          },
                          expression: "form.latitud"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Longitud", prop: "longitud" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.longitud,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "longitud", $$v)
                          },
                          expression: "form.longitud"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Teléfono", prop: "telefono" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "15" },
                        model: {
                          value: _vm.form.telefono,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "telefono", $$v)
                          },
                          expression: "form.telefono"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Email", prop: "email" } },
                    [
                      _c("el-input", {
                        staticClass: "inputEmail",
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "Tipo de Beneficiario", prop: "nombre" }
                    },
                    [
                      _c("maca-select-box", {
                        attrs: { url: _vm.urlTiposBeneficiarios },
                        model: {
                          value: _vm.form.tipo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tipo", $$v)
                          },
                          expression: "form.tipo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Vive", prop: "vive" } },
                    [
                      _c("el-switch", {
                        staticStyle: { display: "block", "margin-top": "7px" },
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                          "active-text": "SI",
                          "inactive-text": "NO"
                        },
                        model: {
                          value: _vm.valueVive,
                          callback: function($$v) {
                            _vm.valueVive = $$v
                          },
                          expression: "valueVive"
                        }
                      })
                    ],
                    1
                  ),
                  !_vm.valueVive
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Fecha de Fallecimiento",
                            prop: "fechaM"
                          }
                        },
                        [
                          !_vm.valueVive
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      "picker-options": _vm.pickerOptions,
                                      placeholder: "Seleccionar fecha",
                                      format: "dd-MM-yyyy",
                                      "value-format": "dd/MM/yyyy"
                                    },
                                    model: {
                                      value: _vm.form.fechaM,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "fechaM", $$v)
                                      },
                                      expression: "form.fechaM"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }